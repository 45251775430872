import { useEffect } from "react"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import Meta from '/components/Meta'
import LoginButton from '/components/LoginButton'
import { useAuth } from "/contexts/auth"
import { S3_FOLDER_PATH, PROJECT_NAME, HIDE_TSF_BRANDING } from '/global.config'

const Login = () => {
  const { login, user, loading } = useAuth()
  const { push, query } = useRouter()

  const redirectUri = () => {
    if (global?.window) {
      return `${window.location.origin}/login`
    }

    return "/login"
  }

  useEffect(() => {
    if (user) {
      push("/calendar")
    }
  }, [user])

  useEffect(() => {
    async function fetchData() {
      if (query?.code) {
        login(query?.code, redirectUri())
      }
    }
    fetchData()
  }, [query?.code])

  return (
    <>
      <Meta title='Login' route='/login' />

      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 font-mono bg-neutral-700 h-screen login-page">
        <div className="relative z-10">
          <div className="relative w-48 h-48 mx-auto">
            <Image
              width={300}
              height={300}
              src={`${S3_FOLDER_PATH}logo.png`}
              alt={PROJECT_NAME}
            />
          </div>

          <div className="mt-4 flex text-4xl items-center flex-shrink-0 px-4 bg-clip-text bg-gradient-to-br text-white text-bold btn-gradient-1 justify-center">
            {PROJECT_NAME}
          </div>
          {!!!HIDE_TSF_BRANDING && (
            <div className="flex justify-center text-xs text-white mt-1 tracking-wider">
              Powered by <Link href='https://www.thestonedfrogs.io/' passHref><a target='_blank'>The Stoned Frogs</a></Link>
            </div>
          )}
        </div>

        <div className="mt-5 sm:mx-auto sm:max-w-md justify-center relative z-10">
          <div className="flex justify-center items-center flex-col">
            <LoginButton className='w-60 text-lg' />
            <Link href='/' passHref>
              <a disabled={loading} className="border border-primary text-secondary w-60 flex justify-center py-2 px-4 rounded-md font-medium mt-5 text-sm">
                Skip Login
              </a>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

Login.getLayout = function getLayout(page) {
  return page
}

export default Login
